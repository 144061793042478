<template>
  <b-container fluid class="mb-4" id="post-page">
    <b-form-textarea
      v-model="post.content"
      class="post-textarea"
      id="textarea-no-resize"
      placeholder="Write here or use @ to tag someone"
      rows="3"
      no-resize
      name="content"
      :class="{ 'is-danger': errors.has('content') }"
      @keydown="tagUser"
    ></b-form-textarea>
    <div class="main-post-box">
      <img :src="image" alt="" id="post-img" v-if="image" />
      <video-embed :src="video" v-if="getHostName(video)"></video-embed>
      <div class="box">
        <button @click="createPost" class="post-input-button">Share</button>
      </div>
      <UserTagSelect v-model="isOpenTag" @tag-user-post="addPostTag" />
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserTagSelect from '../../../components/posts/UserTagSelect.vue';

export default {
  name: 'ShareImage',
  components: {
    UserTagSelect,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    video: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpenTag: false,
      tagIndex: null,
      text: '',
      items: [],
      tagedUsers: {},
      resetUploaders: false,
      post: {
        content: null,
        post_media: [],
        tagged_customers: [],
        tagged_persons: [],
        tags: [],
        user: null,
        company: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/fetchUserInfo',
    }),
  },
  methods: {
    getHostName(url) {
      let videoType = '';
      const regexYoutube = new RegExp(
        /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/,
      );
      const regexVimeo = new RegExp(
        /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/,
      );
      const isYoutubeUrl = regexYoutube.test(url);
      const isVimeoUrl = regexVimeo.test(url);
      if (isYoutubeUrl) videoType = 'youtube';
      if (isVimeoUrl) videoType = 'vimeo';
      return videoType;
    },
    tagUser(simbol) {
      this.tagIndex = simbol.srcElement.selectionEnd;
    },
    ...mapActions({
      addPost: 'posts/addPost',
      shareImageAsPost: 'mediaArchive/shareImageAsPost',
    }),
    async createPost() {
      try {
        this.post.user = this.userInfo.url;

        if (Object.keys(this.tagedUsers).length) {
          const taggedCustomers = [];

          for (const [key, value] of Object.entries(this.tagedUsers)) {
            if (this.post.content.includes(key)) {
              taggedCustomers.push(value);
            }
          }
          this.post.tagged_customers = taggedCustomers;
          const taggedPersons = this.post.tagged_customers.map(
            ({ customer_id }) => customer_id,
          );
          this.post.tagged_persons = taggedPersons;
        }

        if (this.image) {
          this.post.post_media.push({
            media_type: 'image',
            image_field: this.image.replace('https://ccnewwebsite.s3.amazonaws.com/static/', ''),
          });
          await this.shareImageAsPost(this.post);
        }

        if (this.video) {
          this.post.post_media.push({
            media_type: 'video',
            video_field: this.video,
          });

          await this.addPost({ data: this.post, customerId: this.userInfo.id });
        }

        this.$emit('success');
      } catch (err) {
        console.log(err);
      }
    },

    addPostTag(data) {
      if (this.post.content) {
        this.post.content = `${this.post.content.slice(0, this.tagIndex + 1)}${
          data.full_name
        } ${this.post.content.slice(this.tagIndex + 1)}`;
        this.tagedUsers[`${data.full_name}`] = data;
      }
    },

  },
  watch: {
    'post.content': {
      handler(val) {
        if (
          val === '@'
          || val.slice(this.tagIndex - 1, this.tagIndex + 1) === ' @'
          || val.slice(this.tagIndex - 1, this.tagIndex + 1) === '\n@'
        ) {
          this.isOpenTag = true;
        }
      },
    },
  },
};
</script>
<style >
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}
</style>

<style scoped>
img {
  width: 100%;
}
#post-page {
  background-color: white;
  padding: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.post-textarea {
  width: 100%;
}

.video-upload {
  margin-right: 10px;
}
.box {
  padding: 32px 14px 8px 24px;
  margin-bottom: 2px;
}
.form-control {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #d6dad6;
  box-shadow: 0px 0px 0px 0px;
  padding: 11px 1px 1px 14px;
}
::placeholder {
  resize: none;
  font-size: 14px;
  font-family: arial;
}
.post-input-button {
  border-radius: 2px;
  float: right;
  background: #bd9d5f;
  border: none;
  color: white;
  width: 50px;
  margin-top: -24px;
  font-size: 15px;
}
.text-images {
  width: 92px;
  float: left;
  cursor: pointer;
  color: #666666;
  font-size: 12px;
  margin-bottom: 0;
}

.text-videos {
  width: 248px;
  cursor: pointer;
  margin-bottom: 4px;
  color: #666666;
  font-size: 12px;
}
.field-text {
  font-family: Arial;
  font-size: 12px;
  text-align: left;
  color: #ff0000;
  transition: 0.5s;
}
</style>
